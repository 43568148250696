import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "statement"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi! I'm an experienced technology leader who is equally happy dealing with people or computers. I build high performing teams and give smart people the environment they need to do their best work. I am an effective communicator and am comfortable with internal, customer, board or investor interactions. I specialise in early stage startups and have a wealth of experience of rapid development under pressure and managing the trade offs that come with it.`}</p>
    <p>{`I live in Hitchin, Hertfordshire where I spend my time falling off bicycles, walking my dog or taking `}<a parentName="p" {...{
        "href": "https://photos.dombarker.co.uk"
      }}>{`photos`}</a>{`. If you'd like to get in touch feel free to `}<a parentName="p" {...{
        "href": "mailto:dom@dombarker.co.uk"
      }}>{`email me`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      